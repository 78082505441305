@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Roboto;
  user-select: none !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: Roboto;
}

.hide{
  display: none !important;
}

.hidden{
  visibility: hidden !important;
}

a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
