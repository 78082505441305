@import "../../styles/globalstyles.css";
@import "../../styles/colors.css";

.cardPro {
  /* height: 150px;
  width: 150px; */
  border-radius: 12px;
  margin: 15px;
  text-align: center;
  cursor: pointer;
  flex-wrap: wrap;
  box-shadow: 0px 4px 30px rgb(43 98 169 / 50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  font-size: 21px;
  flex-shrink: 0;
  transition: all 0.3s;
  /* padding: 10px; */
}
@media screen and (min-width: 768px) {
  .cardPro:hover {
    transform: scale(1.2);
  }
}

.icon {
  /* height: 100%;
  width: 100%; */
  cursor: pointer;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-animation: 1.4s ease-in-out 0.4s both fade;
  -moz-animation: 1.4s ease-in-out 0.4s both fade;
  animation: 1.4s ease-in-out 0.4s both fade;
}

a {
  text-decoration: none;
  color: #f2f2f2;
}

.cardPro > img {
  width: 80%;
  height: 80%;
}

@media screen and (max-width: 414px) {
  .cardPro {
    box-shadow: none;
  }
}
