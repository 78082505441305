.SkillsWraper {
  width: 100vw;
  height: 100vh;
  /* background: #f5f5f9; */
  background: #1f1e25;
}
.CardGlobalWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 130px;
  overflow: auto;
  height: calc(100vh - 130px);
  width: 100%;
}

.CardGlobalWrapperIn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
