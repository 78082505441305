@import "styles/globalstyles.css";
@import "styles/colors.css";
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none !important;
}

/**** ------------------------- scrollbar -----------------***/

/* width */
::-webkit-scrollbar {
  display: none !important;
  /* width: 5px; */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: transparent; */
  background: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: transparent; */
  background: var(--pinkShade);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--violetShade);
}

.nonClick {
  opacity: 0.3;
  pointer-events: none;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-icon {
  width: 3em !important;
  height: 3em !important;
}
.swal2-icon .swal2-icon-content {
  font-size: 2.75em !important;
}

.center-flex-class {
  display: flex;
  justify-content: center;
  align-items: center;
}

a,
a:hover {
  text-decoration: none;
}
