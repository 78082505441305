:root {
  --cw: #ffffff;
  --c1: #e85a4f;
  --c2: #8e8d8a;
  --c3: #d8c3a5;
  --c4: #e98074;
  --c5: #e85a50;
  --c6: #eae7dc;
  --c7: #1b4067;
  --c8: #edc8b8;
  --c9: #bbb2b5;
  --c10: #ab3b61;
  --c11: #ebdad2;
  --c12: #1b4067;
  --c13: #f07c41;
  --c14: #55bdca;
  --c15: #ab3b61;
  --c16: #016670;
  --c17: #00bc82;
  --c18: #b875d2;
  --c19: #00838d;
  --yellowShade: #f9c531;
  --yellowShade2: #faed23;
  --violetShade: #2b2d5d;
  --violetShade2: #31213c;
  --pinkShade: #f94b6e;
  --violetLightShade: #22234f;
  --whiteShadeOne: #fafafa;
  --blueShade: #626fef;
  --voiletShadeLight: #31213cc4;
  --color: #8fc866;
  --hover: #66c887;
  --black1: #222629;
  --green1: #87c231;
  --green2: #618930;
  --dualbox_c1: #8459fe;
  --dualbox_c2: #ffa2bf;
  --dualbox_c3: #201f31;
  --card_2: #e73c7e;
  --card_1: #ee7752;
  --card_4: #23d5ab;
  --card_3: #23a6d5;
  --card_5: #5e72e5;
  --color-app: #071b2f;
  --color-ab: #6c63ff;
  --color-ab2: #3f3d56;
  --color-home-card-focus: #6c44fc;
  --color-header-card-contrast: white;
  --color-bg-black-shade : #1f1e25
}
