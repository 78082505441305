@import "../../styles/globalstyles.css";
@import "../../styles/colors.css";

.skillsCard {
  background-color: #131015;
  height: auto;
  width: 230px;
  border-radius: 12px;
  margin: 8px;
  padding: 25px 20px;
  color: white;
}

.skillsetsarr {
  width: 100%;
}
.skill_set {
  display: flex;
  align-items: center;
  background-color: #1f1e25;
  padding: 10px;
  border-radius: 12px;
  margin: 10px 0;
  /* width: 100%; */
}
.skill_icon {
  height: 40px;
  width: 40px;
}
.skill_icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
}
.skill_details {
  width: 100%;
  margin-left: 10px;
}
.skill_details_Child {
  margin: 10px 0;
}
.skill_details_section_1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-container {
  box-shadow: 0 4px 5px rgb(0, 0, 0, 0.1);
}

.progress-container,
.progress {
  background-color: #eee;
  border-radius: 5px;
  height: 7px;
  width: 100%;
}

.progress {
  transition: width 0.4s linear;
}
/* .pbc1 {
  background-color: #a470f8;
}
.pbc2 {
  background-color: #84dcc5;
}
.pbc3 {
  background-color: #f3d547;
}
.pbc4 {
  background-color: #f25f5c;
} */
