@import "../../styles/globalstyles.css";
@import "../../styles/colors.css";

.profilePageWrap {
  width: 100vw;
  height: 100vh;
  background-color: var(--black1);
  display: flex;
  flex-direction: column;
}

.profileSectionPar {
  position: relative;
  top: 100px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* width: 100%; */
  padding: 0 20px;
  height: calc(100vh - 100px);
}

.profileSection {
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-wrap: wrap;
  max-width: 768px;
}

@media screen and (max-width: 414px) {
  .profileSection {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .profileSectionPar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
