@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);

:root {
  --cw: #ffffff;
  --c1: #e85a4f;
  --c2: #8e8d8a;
  --c3: #d8c3a5;
  --c4: #e98074;
  --c5: #e85a50;
  --c6: #eae7dc;
  --c7: #1b4067;
  --c8: #edc8b8;
  --c9: #bbb2b5;
  --c10: #ab3b61;
  --c11: #ebdad2;
  --c12: #1b4067;
  --c13: #f07c41;
  --c14: #55bdca;
  --c15: #ab3b61;
  --c16: #016670;
  --c17: #00bc82;
  --c18: #b875d2;
  --c19: #00838d;
  --yellowShade: #f9c531;
  --yellowShade2: #faed23;
  --violetShade: #2b2d5d;
  --violetShade2: #31213c;
  --pinkShade: #f94b6e;
  --violetLightShade: #22234f;
  --whiteShadeOne: #fafafa;
  --blueShade: #626fef;
  --voiletShadeLight: #31213cc4;
  --color: #8fc866;
  --hover: #66c887;
  --black1: #222629;
  --green1: #87c231;
  --green2: #618930;
  --dualbox_c1: #8459fe;
  --dualbox_c2: #ffa2bf;
  --dualbox_c3: #201f31;
  --card_2: #e73c7e;
  --card_1: #ee7752;
  --card_4: #23d5ab;
  --card_3: #23a6d5;
  --card_5: #5e72e5;
  --color-app: #071b2f;
  --color-ab: #6c63ff;
  --color-ab2: #3f3d56;
  --color-home-card-focus: #6c44fc;
  --color-header-card-contrast: white;
  --color-bg-black-shade : #1f1e25
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none !important;
}

/**** ------------------------- scrollbar -----------------***/

/* width */
::-webkit-scrollbar {
  display: none !important;
  /* width: 5px; */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: transparent; */
  background: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: transparent; */
  background: var(--pinkShade);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--violetShade);
}

.nonClick {
  opacity: 0.3;
  pointer-events: none;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-icon {
  width: 3em !important;
  height: 3em !important;
}
.swal2-icon .swal2-icon-content {
  font-size: 2.75em !important;
}

.center-flex-class {
  display: flex;
  justify-content: center;
  align-items: center;
}

a,
a:hover {
  text-decoration: none;
}

.headingProfile {
  /* height: 60px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: fixed;
  width: 100%;
  /* padding: 16px 0px; */
  z-index: 8;
  /* transform: translate3d(0px, 0px, 0px); */
  border-radius: 0 0 50% 50%;
  /* border-radius: 0% 0% 150% 150%/0% 0% 25% 25%; */
  /* height: 35vh; */
  height: 100px;
}

.headingProfileWrapper {
  width: 1024px;
  position: relative;
}
.backIcon {
  width: 3.5rem;
  display: inline-block;
  margin: 1.2rem 5rem 1.2rem 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.backIcon > img {
  height: 100%;
  width: 100%;
}

.h1c {
  width: 100%;
  text-align: center;
  margin: 3.2rem 0rem;
  color: white;
}
.cpf {
  color: var(--green1);
  background: transparent;
}

.Swd {
  color: white;
}
.swdbcg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  /* background-size: 400% 400% */
  /* animation: gradient 15s ease infinite;  */
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1024px) {
  .headingProfile {
    border-radius: unset;
  }
  .backIcon {
    left: 15px;
  }
}
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 414px) {
  .backIcon {
    width: 2.5rem;
    top: 12px;
  }
  .h1c {
    font-size: 20px;
  }
}

.cardPro {
  /* height: 150px;
  width: 150px; */
  border-radius: 12px;
  margin: 15px;
  text-align: center;
  cursor: pointer;
  flex-wrap: wrap;
  box-shadow: 0px 4px 30px rgb(43 98 169 / 50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  font-size: 21px;
  flex-shrink: 0;
  transition: all 0.3s;
  /* padding: 10px; */
}
@media screen and (min-width: 768px) {
  .cardPro:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

.icon {
  /* height: 100%;
  width: 100%; */
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  -webkit-animation: 1.4s ease-in-out 0.4s both fade;
  animation: 1.4s ease-in-out 0.4s both fade;
}

a {
  text-decoration: none;
  color: #f2f2f2;
}

.cardPro > img {
  width: 80%;
  height: 80%;
}

@media screen and (max-width: 414px) {
  .cardPro {
    box-shadow: none;
  }
}

.profilePageWrap {
  width: 100vw;
  height: 100vh;
  background-color: var(--black1);
  display: flex;
  flex-direction: column;
}

.profileSectionPar {
  position: relative;
  top: 100px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* width: 100%; */
  padding: 0 20px;
  height: calc(100vh - 100px);
}

.profileSection {
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-wrap: wrap;
  max-width: 768px;
}

@media screen and (max-width: 414px) {
  .profileSection {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .profileSectionPar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ArtistPageWrap {
  width: 100vw;
  height: 100vh;
  /* background: #f5f5f9; */
  background: #1f1e25;
}
.ArtImagesWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 130px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 130px);
  width: 100%;
}

.ArtImagesWrapperIn {
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}

.art-gallery-item {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 24rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  cursor: pointer;
}

.art-gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: -webkit-transform 400ms ease-out;
  transition: transform 400ms ease-out;
  transition: transform 400ms ease-out, -webkit-transform 400ms ease-out;
}

/* @supports (display: grid) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    grid-gap: 2rem;
  }

  .gallery,
  .gallery-item {
    margin: 0;
  }
} */

@media screen and (max-width: 414px) {
  .art-gallery-item {
    flex: 1 0 18rem;
  }
}

@media (min-width: 768px) {
  .ArtImagesWrapperIn {
    max-width: 1320px;
  }
}
@media (min-width: 1025px) {
  .art-gallery-image:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
}

:root{
 --left-sec-width : 23%;
 --middle-sec-width : 42%;
 --right-sec-width : 35%;
}
.w100 {
  width: 100%;
}
.flex-dir-col {
  flex-direction: column;
}

.txt-al-cnt {
  text-align: center;
}

.HomePageWrap {
  /* width: 100%;
  height: 100%;
   */
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* background:  url(../../assets/images/mountain4.jpg);
 background-repeat: no-repeat;
 background-size: cover;
  position: relative; */
}

.HomePage {
  /* background:#071b2f ; */
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  height: 100%;

  position: relative;
  /* cursor: pointer; */
  z-index: 2;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--voiletShadeLight);
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

.upperHead {
  background-color: var(--c16);
  height: 60px;
  /* border-radius: 0 0 50% 50%; */
}

.profileIntroSec {
  /* margin-top: 60px; */
  width: 100%;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.profilename {
  width: 418px;
  height: 98px;
  font-size: 48px;
  font-weight: bold;
  color: var(--yellowShade2);
  /* text-transform: uppercase; */
}

.profiledesg {
  width: 418px;
  height: 98px;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  color: var(--cw);
}

.profileDescDiv {
  /* margin-top: 20px; */
  /* width: 100%;
  height: 400px; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profileDescPart {
  /* flex: 1; */
  height: inherit;
  /* border: 1px solid; */
}

.middleSec {
  position: absolute;
  z-index: 2;
  left: 46vw;
}
.rightSec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  width: var(--right-sec-width);
}

.profile-cards-parent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* position: relative; */
  height: 472px;
  width: 472px;
}

.profile-cards {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* flex-direction: column; */
  height: 200px;
  width: 200px;
  margin: 18px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
  /* box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 /  */
  /* transform: scale(1); */
}

.pfc-up,
.pfc-down {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.pfc-up:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  color: #071b2f !important;
  background: var(--yellowShade2);
  /* background: #00838d; */
  /* background: linear-gradient(to right, #42275a , #734b6d); */
  height: 32px;
  width: 32px;
  border-bottom-left-radius: 12px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  -webkit-transition: transform 0.25s ease-out;
}
.pfc-down:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: 0px;
  background: var(--yellowShade2);
  /* background: #00838d; */
  /* background: linear-gradient(to right, #42275a , #734b6d); */
  height: 32px;
  width: 32px;
  border-top-left-radius: 12px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  -webkit-transition: transform 0.25s ease-out;
}
.pfc-up:hover:before,
.pfc-down:hover:before {
  -webkit-transform: scale(21);
          transform: scale(21);
}

.pfc-up:hover .cs-font {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  color: #071b2f !important;
}
.pfc-up:hover .cf-font {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  color: #071b2f !important;
}
.pfc-down:hover .ca-font {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  color: #071b2f !important;
}
.pfc-down:hover .cp-font {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  color: #071b2f !important;
}

.cs {
  /* background-color: var(--c18); */
  background: linear-gradient(to right, #de6262, #ffb88c);

  color: white;
}
.cf {
  /* background-color: var(--c13); */
  background: linear-gradient(to right, #185a9d, #43cea2);
  color: white;
}
.ca {
  /* background-color: var(--c14);*/
  background: linear-gradient(to right, #4568dc, #b06ab3);
  color: white;
}
.cp {
  /* background-color: var(--c15); */
  background: linear-gradient(to right, #dd2476, #ff512f);
  color: white;
}
.cs-font {
  color: white !important;
}
.cf-font {
  color: white !important;
}
.ca-font {
  color: white !important;
}
.cp-font {
  color: white !important;
}

.profileImagePar {
  cursor: pointer;
  border-radius: 50%;
  background: var(--color-app);
  height: 190px;
  width: 190px;
}
.profileImageOuterCircle {
  border-radius: 50%;
  /* background-color: var(--yellowShade2);; */
  height: 140px;
  width: 140px;
  border: 4px solid var(--yellowShade2);
}

.profileImageDiv {
  border-radius: 50%;
  /* background-color: white; */
  background-color: transparent;
  height: 120px;
  min-width: 120px;
}

.card-headings {
  border-radius: 50%;
  font-weight: bold;
  background: #0000004d;
  font-size: 24px;
  padding: 5px;
  height: 100px;
  width: 100px;
  /* padding: 20px;
  height: fit-content;
  width: fit-content; */
  /* font-weight: bold;
  font-size: 16px;
  height: inherit;
  width: inherit; */
}

.fsd {
  word-break: break-word;
  width: 100px;
}

.go-corner-up {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;
  overflow: hidden;
  top: 0;
  right: 0;
  /* background-color:var(--c19); */
  /* background: linear-gradient(to right, #42275a , #734b6d); */
  background-color: var(--yellowShade2);
  border-bottom-left-radius: 40px;
}
.go-arrow-up {
  margin-top: -4px;
  margin-right: -4px;
}

.go-corner-down {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;
  overflow: hidden;
  bottom: 0;
  right: 0;
  background-color: var(--yellowShade2);
  /* background: linear-gradient(to right, #42275a , #734b6d); */
  border-top-left-radius: 40px;
}
.go-arrow-down {
  margin-top: 1px;
  margin-right: -7px;
}

.go-arrow {
  /* color: white; */
  color: #071b2f;
  font-family: courier, sans;
}

.profile-pic-card-front > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.profile-pic-card-back > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profile-pic-card {
  background-color: #7b3bc6;
  width: inherit;
  height: inherit;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  cursor: pointer;
}

.profile-pic-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.profile-pic-card:hover .profile-pic-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.profile-pic-card-front,
.profile-pic-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* border: 2px solid var(--yellowShade2); */
}

.profile-pic-card-front {
  background-color: #bbb;
  color: black;
}

.profile-pic-card-back {
  /* border: 2px solid var(--c16); */
  /* background-color: var(--c12); */
  background: linear-gradient(to right, #000428, #004e92);
  color: white;
  font-size: 12px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.desg {
  margin: 5px 0px;
}

.profile-card-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  /* margin-top: 40px; */
}
.leftSec {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../assets/images/developer_activity.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  width: 65%;
}

.leftSecInner {
  background-color: var(--color-app);
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
}
.leftImagePar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
}
.leftImagePar > img {
  height: 100%;
  width: 100%;
}
/* .rightSec{
 display: flex;
 justify-content: center;
 align-items: center;
 height: inherit;
 width: 250px;
} */
.rightImagePar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
}
.rightImagePar > img {
  height: 100%;
  width: 100%;
}

.MyIntoPar {
  /* background: var(--color-bg-black-shade); */
  background-image: linear-gradient(-20deg, #ff2846 0%, #6944ff 100%);
  width: 42%;
  width: var(--middle-sec-width);
}
.introPar {
  /* min-height: 460px; */
  box-shadow: 0px 8px 60px -10px rgb(13 28 39 / 60%);
  background: #fff;
  border-radius: 12px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 700px;
}

.introPar__img {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #6c44fc, 0px 0px 0px 7px rgba(107, 74, 255, 0.5);
}

.introPar__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.introParHead {
  margin-top: -35px;
  text-align: center;
  padding: 0 40px;
  padding-bottom: 40px;
  transition: all 0.3s;
}

.uname {
  font-weight: 700;
  font-size: 36px;
  color: #6944ff;
  margin-bottom: 15px;
  height: 40px;
}
.utxt {
  font-size: 24px;
  font-weight: 500;
  color: #324e63;
  margin-bottom: 15px;
}
.utxt strong {
  font-weight: 700;
}
.loc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #324e63;
}
.loc-icon {
  display: inline-flex;
  font-size: 27px;
  margin-right: 10px;
}

.loc-icon-img {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
/* .introParHead{
  
} */

.introParHead .span1 {
  font-size: 24px;
  font-weight: bold;
  color: var(--cw);
}
.introParHead .span2 {
  font-size: 48px;
  font-weight: bold;
  color: var(--color-home-card-focus);
}
.introParHead .span3 {
  font-size: 24px;
  font-weight: bold;
  color: var(--cw);
  /* font-style: italic; */
}
.introParHead .span31 {
  font-size: 24px;
  font-weight: bold;
  color: var(--cw);
}

.introParHead .span4 {
  /* font-size: 14px;
  font-weight: bold; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 30px;
  color: white;
}

.introParHead .span4 .span4-badges {
  padding: 15px;
  border-radius: 12px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px 10px 0px 0px;
}

/* .introParHead .span4 .span4-badges:nth-child(1) {
  background: linear-gradient(to right, #de6262, #ffb88c);
  color: var(--cw);
}
.introParHead .span4 .span4-badges:nth-child(2) {
  background: linear-gradient(to right, #185a9d, #43cea2);
  color: var(--cw);
}
.introParHead .span4 .span4-badges:nth-child(3) {
  background: linear-gradient(to right, #4568dc, #b06ab3);
  color: var(--cw);
}
.introParHead .span4 .span4-badges:nth-child(4) {
  background: linear-gradient(to right, #dd2476, #ff512f);
  color: var(--cw);
} */

.introParHead .span4 .span4-badges:nth-child(odd) {
  background: transparent;
  color: #071b2f;
  border: 1px solid #071b2f;
}

.introParHead .span4 .span4-badges:nth-child(even) {
  background: #071b2f;
  color: white;
}

.introParBody {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* flex-direction:column; */
  /* border-radius: 12px; */
  padding: 5px;
  color: var(--cw);
  /* animation: spin 2.5s infinite;
  transform:rotate(360deg) */
}

.introParBody .span2 {
  font-size: 16px;
  height: 30px;
  width: 30px;
  /* background: var(--c16); */
  background: linear-gradient(to right, #56ab2f, #a8e063);
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px 
    padsolid; */
  /* border-radius: 8px; */
}

.sp1 {
  border-top-left-radius: 27px;
}
.sp2 {
  border-top-right-radius: 27px;
}
.sp3 {
  border-bottom-left-radius: 27px;
}
.sp4 {
  border-bottom-right-radius: 27px;
}

.introParHead .span5 {
  font-size: 20px;
  /* font-weight: bold; */
  color: var(--color-app);
  padding: 10px 0px 10px 0px;
  /* word-spacing: 5px; */
  height: 50px;
  align-items: center;
}

.call {
  margin: 0 5px;
}
.col51 {
  color: var(--color-home-card-focus);
}
.col5 {
  color: #a239b6;
}

.col52 {
  color: var(--cw);
}

.btnAboutMeParent {
  margin-top: 40px;
}

/*

  .btnAboutMe {
    color:#fff;
    text-align: center;
    cursor: pointer;
    font-size:24px;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid var(--yellowShade2);
    background: transparent;
    width: 200px;
    transition: all 0.5s;
    position: relative;
  }
  

  .btnAboutMe span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btnAboutMe span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .btnAboutMe:hover span {
    padding-right: 25px;
  }
  
  .btnAboutMe:hover span:after {
    opacity: 1;
    right: 0;
  } */

.slide:hover {
  box-shadow: inset 8em 0 0 0 var(--color-home-card-focus);
  border-color: var(--color-home-card-focus);
  color: #071b2f;
  font-weight: bold;
}

.slide {
  color: var(--color-home-card-focus);
  transition: 0.25s;
  -webkit-transition: 0.25s;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.slide {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 5px 5px 5px 0;
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;
}

.imagesSvg {
  padding: 15px;
  position: absolute;
  border-radius: 12px;
}
.solution_mindset {
  bottom: 0px;
  left: 43vw;
  background: var(--cw);
}

.solution_mindset div > img {
  height: 100%;
  width: 100%;
}

.developer {
  top: 0px;
  left: 43vw;
  background: var(--color-app);
}

.developer div > img {
  height: 100%;
  width: 100%;
}

.imgPar {
  padding: 15px;
  height: 75px;
  width: 150px;
  border-radius: 12px;
  background: white;
}

.m {
  border: 2px solid var(--color-app);
}
.s {
  border: 5px solid var(--whiteShadeOne);
}

.sideClass {
  background: var(--color-bg-black-shade);
  /* background: var(--color-app); */
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 23%;
  width: var(--left-sec-width);
}

.subCLs {
  padding: 5px;
  /* height: 100px;
  width: 100px; */
  border-radius: 12px;
  background: var(--cw);
  margin: 10px;
}

.imgPar > img {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .subCLs:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

.subCLs {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  -webkit-animation: 1.4s ease-in-out 0.4s both fade;
  animation: 1.4s ease-in-out 0.4s both fade;
}
.svg1 {
  background: linear-gradient(to right, #dd2476, #ff512f);
}
.svg2 {
  background: linear-gradient(to right, #185a9d, #43cea2);
}
.svg3 {
  background: linear-gradient(to right, #de6262, #ffb88c);
}

.contactCLass {
  width: 100%;
}

.contactCLassChild {
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-card__button {
  background: none;
  border: none;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 19px;
  /* margin: 10px 5px; */
  padding: 15px 40px;
  min-width: 201px;
  border-radius: 50px;
  min-height: 55px;
  color: #fff;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 0.3s;
}

.profile-card__button:focus {
  outline: none !important;
}
@media screen and (min-width: 768px) {
  .profile-card__button:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}
.profile-card__button:first-child {
  margin-left: 0;
}
.profile-card__button:last-child {
  margin-right: 0;
}
.profile-card__button.button--blue {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}
.profile-card__button.button--blue:hover {
  box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}
.profile-card__button.button--orange {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}
.profile-card__button.button--orange:hover {
  box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
}
.profile-card__button.button--gray {
  box-shadow: none;
  background: #dcdcdc;
  color: #142029;
}
.profile-card-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 130px;
  padding-bottom: 100px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.profile-card-form {
  box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 35px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  position: relative;
  z-index: 3;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .profile-card-form {
    max-width: 90%;
    height: auto;
  }
}

.profile-card-form__bottom {
  justify-content: space-between;
  display: flex;
}

.profile-card textarea {
  width: 100%;
  resize: none;
  height: 210px;
  margin-bottom: 20px;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 20px;
  color: #324e63;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  outline: none;
  transition: all 0.3s;
}
.profile-card textarea:focus {
  outline: none;
  border-color: #8a979e;
}
.profile-card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(22, 33, 72, 0.35);
  border-radius: 12px;
  transition: all 0.3s;
}

.w-100 {
  width: 100% !important;
}

.aboutIcon {
  height: 75px;
  width: 150px;
  padding: 5px;
}
.no-border {
  border: 0 !important;
}
.aboutImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.pAbout {
  line-height: 1.4;
}
/* --------------- responsive -------------- */


@media screen and (max-width: 1024px) {
  .sideClass {
    width: 35%;
  }
  .MyIntoPar {
    width: 40%;
    padding: 20px;
  }
  .rightSec {
    width: 25%;
  }
  .profile-cards-parent {
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
  .HomePageWrap {
    overflow: hidden;
  }
  .introParHead {
    padding-left: 0px;
    padding-right: 0px;
  }
  .profile-card__button {
    min-width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .HomePageWrap {
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  .profileDescDiv {
    flex-direction: column !important;
    justify-content: unset !important;
  }
  .profileDescPart {
    height: auto;
  }
  .sideClass {
    /* display: none !important; */
    width: 100%;
  }
  .MyIntoPar {
    padding: 100px 0px 30px 0px !important;
    width: 100% !important;
  }
  .rightSec {
    width: 100% !important;
  }
  .introParHead {
    padding-left: 30px;
    padding-right: 30px;
  }
  .profile-cards {
    height: 150px;
    width: 150px;
  }
  .profile-cards-parent {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }
  .card-headings {
    height: 65px;
    width: 65px;
    font-size: 16px;
  }
  .profile-card__button {
    margin: 5px;
  }
}

@media screen and (max-width: 414px) {
  .profile-card-ctr {
    flex-wrap: wrap;
  }
  .introPar {
    width: 360px;
  }
  .introPar__img {
    width: 120px;
    height: 120px;
  }
  .profile-card-form__bottom {
    flex-wrap: wrap;
  }
  .profile-card-form {
    padding: 20px;
  }
  .profile-card__button:last-child {
    margin-bottom: 0;
  }
  .introParHead .span4 {
    margin: 20px 0px;
  }
  .introParHead .span4 .span4-badges {
    padding: 10px 15px;
    margin: 5px;
    width: 100px;
  }
  .introParHead .span4 .span4-badges:nth-child(1) {
    background: transparent;
    color: #071b2f;
    border: 1px solid #071b2f;
  }
  .introParHead .span4 .span4-badges:nth-child(4) {
    background: transparent;
    color: #071b2f;
    border: 1px solid #071b2f;
  }
  .introParHead .span4 .span4-badges:nth-child(2) {
    background: #071b2f;
    color: white;
  }
  .introParHead .span4 .span4-badges:nth-child(3) {
    background: #071b2f;
    color: white;
  }
  .profile-cards {
    height: 100px;
    width: 100px;
  }
  .card-headings {
    height: 60px;
    width: 60px;
    font-size: 14px;
  }
}

.btnOrange {
  background: linear-gradient(45deg, #d5135a, #f05924) !important;
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35) !important;
}

.leftmid{
   background-color: #131015;
    height: auto;
    width: 80%;
    border-radius: 12px;
    margin: 8px;
    padding: 30px 30px;
    color: white;
}

.leftmid h1{
  font-family: 'Pacifico', cursive;
  color: #f25f5c;
}
.leftmid h4{
  font-family: 'Roboto';
  color: #84dcc5;
}
.aboutSection{
   /* background: #131015; */
   padding: 10px 20px;
   border-radius: 12px;
   width: inherit;
   /* margin: 10px 0; */
  }
  .user__story{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.story__points{
    display: flex;
    align-items: center;
    background-color: #1f1e25;
    padding: 10px;
    border-radius: 6px;
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    font-family: 'Courgette', cursive;
    border-left: 3px solid;
}
.bullets{
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.bp1{
  background: #a470f8;
}
.bp2{
  background: #84dcc5;
}
.bp3{
  background: #f3d547;
}
.bp4{
  background: #f25f5c;
}
.point__txt{
  margin-left: 10px;
  font-size: 14px;
  width: calc(100% - 20px);
}
.sc1{ border-color: #a470f8}
.sc2{ border-color: #84dcc5}
.sc3{ border-color: #f3d547}
.sc4{ border-color: #f25f5c}


.ProjectsPageContainer {
  width: 100vw;
  height: 100vh;
  background: #f5f5f9;
  /* background: #1f1e25; */
  background: linear-gradient(to right, #f0edf7, #f4eaed);
}
.ProjectsWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 130px;
  overflow: auto;
  height: calc(100vh - 130px);
  width: 100%;
}

.ProjectsWrapperIn {
  max-width: 1368px;
}
.project__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.project__card__container {
  flex-basis: 30%;
  padding: 1rem;
}

.project__card {
  width: 100%;
  min-width: 20rem;
  min-height: 18rem;
  display: flex;
  flex-direction: column;
  background-color: var(--color-canvas-default);
  border-color: var(--color-border-default);
  /* border: 1px solid #30363d; */
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  background: white;
  box-sizing: border-box;
  cursor: pointer;
}

.project__image {
  background-color: #c05252;
  border-radius: 10px;
  height: 10em;
}

.project__body {
  padding: 16px;
}

.project__title {
  font-size: 18px;
  font-weight: bolder;
  margin: 10px 0px;
}

.proejct__technologies {
  color: #b653c6;
  background: #f2dcf5;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px 0px;
}

.proejct__desciption {
  color: #838383;
  font-size: 14px;
}

/*media qyeries */
@media (max-width: 1300px) {
  .project__wrapper .project__card__container {
    flex-basis: 33.3%;
  }
}
@media (max-width: 970px) {
  .project__wrapper .project__card__container {
    flex-basis: 50%;
  }
}
@media (max-width: 660px) {
  .project__wrapper .project__card__container {
    flex-basis: 100%;
  }
}

.skillsCard {
  background-color: #131015;
  height: auto;
  width: 230px;
  border-radius: 12px;
  margin: 8px;
  padding: 25px 20px;
  color: white;
}

.skillsetsarr {
  width: 100%;
}
.skill_set {
  display: flex;
  align-items: center;
  background-color: #1f1e25;
  padding: 10px;
  border-radius: 12px;
  margin: 10px 0;
  /* width: 100%; */
}
.skill_icon {
  height: 40px;
  width: 40px;
}
.skill_icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
}
.skill_details {
  width: 100%;
  margin-left: 10px;
}
.skill_details_Child {
  margin: 10px 0;
}
.skill_details_section_1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-container {
  box-shadow: 0 4px 5px rgb(0, 0, 0, 0.1);
}

.progress-container,
.progress {
  background-color: #eee;
  border-radius: 5px;
  height: 7px;
  width: 100%;
}

.progress {
  transition: width 0.4s linear;
}
/* .pbc1 {
  background-color: #a470f8;
}
.pbc2 {
  background-color: #84dcc5;
}
.pbc3 {
  background-color: #f3d547;
}
.pbc4 {
  background-color: #f25f5c;
} */

.SkillsWraper {
  width: 100vw;
  height: 100vh;
  /* background: #f5f5f9; */
  background: #1f1e25;
}
.CardGlobalWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 130px;
  overflow: auto;
  height: calc(100vh - 130px);
  width: 100%;
}

.CardGlobalWrapperIn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.NotFoundPageWrap {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.pageParent {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
}

.notFoundImage {
  width: 100%;
  max-width: 768px;
  height: 290px;
  max-width: 500px;
}
.notFoundImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.btnGoHome {
  background: #fa6142;
  border-radius: 6px;
  outline: none !important;
  border: none !important;
  padding: 10px;
  font-weight: bold;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 414px) {
  .btnGoHome {
    font-size: 16px;
  }
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(/static/media/Roboto-Regular.a8d6ac03.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Roboto;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: Roboto;
}

.hide{
  display: none !important;
}

.hidden{
  visibility: hidden !important;
}

a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}

