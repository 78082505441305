.headingProfile {
  /* height: 60px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: fixed;
  width: 100%;
  /* padding: 16px 0px; */
  z-index: 8;
  /* transform: translate3d(0px, 0px, 0px); */
  border-radius: 0 0 50% 50%;
  /* border-radius: 0% 0% 150% 150%/0% 0% 25% 25%; */
  /* height: 35vh; */
  height: 100px;
}

.headingProfileWrapper {
  width: 1024px;
  position: relative;
}
.backIcon {
  width: 3.5rem;
  display: inline-block;
  margin: 1.2rem 5rem 1.2rem 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.backIcon > img {
  height: 100%;
  width: 100%;
}

.h1c {
  width: 100%;
  text-align: center;
  margin: 3.2rem 0rem;
  color: white;
}
.cpf {
  color: var(--green1);
  background: transparent;
}

.Swd {
  color: white;
}
.swdbcg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  /* background-size: 400% 400% */
  /* animation: gradient 15s ease infinite;  */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1024px) {
  .headingProfile {
    border-radius: unset;
  }
  .backIcon {
    left: 15px;
  }
}
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 414px) {
  .backIcon {
    width: 2.5rem;
    top: 12px;
  }
  .h1c {
    font-size: 20px;
  }
}
