.ArtistPageWrap {
  width: 100vw;
  height: 100vh;
  /* background: #f5f5f9; */
  background: #1f1e25;
}
.ArtImagesWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 130px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 130px);
  width: 100%;
}

.ArtImagesWrapperIn {
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}

.art-gallery-item {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 24rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  cursor: pointer;
}

.art-gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

/* @supports (display: grid) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    grid-gap: 2rem;
  }

  .gallery,
  .gallery-item {
    margin: 0;
  }
} */

@media screen and (max-width: 414px) {
  .art-gallery-item {
    flex: 1 0 18rem;
  }
}

@media (min-width: 768px) {
  .ArtImagesWrapperIn {
    max-width: 1320px;
  }
}
@media (min-width: 1025px) {
  .art-gallery-image:hover {
    transform: scale(1.15);
  }
}
